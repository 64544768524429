.separator-password-policy {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator-password-policy::before,
.separator-password-policy::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.separator-password-policy:not(:empty)::before {
  margin-right: 0.25em;
}

.separator-password-policy:not(:empty)::after {
  margin-left: 0.25em;
}

li.fail::before {
  content: "•";
  color: #657080;
  margin-right: 1em;
  display: inline-block;
  margin-left: 0.6em;
}

li.success::before {
  content: "•";
  color: #12b536;
  margin-right: 1em;
  display: inline-block;
  margin-left: 0.6em;
}
