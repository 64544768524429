.table {

    thead tr:first-child th:last-child {
        padding-right: 24px;
    }

    thead tr:first-child th:first-child {
        padding-left: 24px;
    }
    
    tbody tr td:first-child {
        padding-left: 24px;
    }
}