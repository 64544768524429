.emptyTable {
  table {
    border-collapse: collapse !important;
    width: 100%;
  }

  th {
    padding: 16px 24px;
    border: none !important;
    border-collapse: collapse;
  }
  td {
    border-collapse: collapse;
    min-width: 100px;
    padding-top: 24px !important;
    padding-bottom: 0 !important;
    border: none !important;
  }
  tr:first-child td {
    padding-top: 0px !important;
  }
  th:last-child {
    border-top-right-radius: 0px !important;
  }
  th:first-child {
    border-top-left-radius: 0px !important;
  }

  tr {
    border: none !important;
  }
  table tr:last-child td:first-child {
    border-bottom-left-radius: 0px !important;
  }
  table td {
    border: none !important;
  }
  table tr:last-child td:last-child {
    border-bottom-right-radius: 0px !important;
  }
}
