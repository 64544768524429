@import url("@100mslive/roomkit-react/index.css");
@tailwind base;

:root {
  --user-font-roboto: "Roboto mono", sans-serif;
}
/* Firefox */
* {
  scrollbar-width: 8px;
  scrollbar-color: #272e38 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #272e38;
  border-radius: 5px;
}

@tailwind components;
@tailwind utilities;

.introjs-helperNumberLayer {
  left: 100% !important;
  background: #2f80ff !important;
}

.introjs-tooltipbuttons {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.coachmark-roles-layer:after {
  content: "Roles";
  position: absolute;
  top: 82%;
  left: 73%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.coachmark-rooms-layer:after {
  content: "Rooms";
  position: absolute;
  top: 82%;
  left: 73%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.copy-notification {
  position: absolute;
  left: -44px;
  top: -26px;
  width: 100px;
  background: #2f80ff;
  padding: 0px 4px;
  border-radius: 6px;
  animation: fade-notification;
  animation-duration: 800ms;
  opacity: 1;
}

.config-subheading {
  scroll-margin-top: 100px;
}

@keyframes fade-notification {
  60% {
    opacity: 1;
    top: -26px;
  }
  100% {
    opacity: 0;
    top: -40px;
  }
}

@media (max-height: 770px) {
  .login-page-text-left-method {
    display: none;
  }
  .login-page-text-left-method2 {
    margin-top: 2% !important;
  }

  .login-page-text-left-method3 {
    padding-top: 1% !important;
  }
}

.PhoneInputCountrySelect {
  @apply bg-surface-light h-10 border-border-light border;
}

.PhoneInputCountryIcon {
  @apply mb-2 bg-inherit !important;
}

.PhoneInputCountryIcon--border {
  background-color: none !important;
  box-shadow: none !important;
}

.PhoneInputInput {
  @apply bg-surface-light h-10 rounded-md border-border-light border;
}

.PhoneInputInput:focus {
  outline: none;
}

.font-inter :target {
  scroll-margin-top: 100px;
}
