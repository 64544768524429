img, svg, video, canvas, audio, embed, object{
    display: inline !important; // overiding tailwind's properties
}

@mixin custom-toggle-button{
    input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }
    
    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 28px;
        height: 14px;
        background: #cccccc;
        opacity: 1;
        border-radius: 7px;
        display: block;
        position: relative;
    }
    
    label:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-70%, -50%);
        width: 20px;
        height: 20px;
        background: #8f8f8f;
        border-radius: 50%;
        transition: 0.3s;
    }
    
    input:checked + label {
        background: #193A6E;
    }
    
    input:checked + label:after {
        top: 50%;
        left: 0%;
        transform: translate(70%, -50%);
        background: #2F80FF;
    }
}

@mixin custom-scrollbar {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: transparent;
    }
    
    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #464a50;
        border-radius: 5px;
    }
}

.app-builder-landing{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 6rem - 60px);
    @media screen and (max-width: 768px) {
        @include custom-scrollbar;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 6.25rem - 60px);
    }
    .breadcrums-row{
        background-color: #0B326F;
        font-size: 12px;
        height: 32px;
        user-select: none;
        .breadcrum-header{
            @media screen and (max-width: 632px) {
                display: none;
            }
        }
        .breadcrum-inactive{
            color: rgba(255,255,255,0.25);
            .breadcrum-arrow{
                border-color: rgba(255,255,255,0.25);
            }
        }
        .breadcrum-active{
            color: white;
            cursor: pointer;
            .breadcrum-arrow{
                border-color: white;
            }
        }
        .breadcrum-arrow{
            border: solid;
            border-width: 0 2pt 2pt 0;
            display: inline-block;
            padding: 2pt;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
    }
    .main-app-builder{
        flex-grow: 1;
        .platform-app-builder{
            width: 100%;
            height: 100%;
            .platform-card{
                display: flex;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #B0C3DB;
                &:hover{
                    background-color: #303740;
                    cursor: pointer;
                }
                .platform-icon{
                    width: 60px;
                }
            }
            .platform-card-active{
                background-color: #303740;
                border: 1px solid #868686;
            }
        }
        .template-app-builder{
            width: 100%;
            .template-cards-row{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                .template-card{
                    display: flex;
                    overflow: hidden;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: #B0C3DB;
                    &:hover{
                        background-color: #303740;
                        cursor: pointer;
                    }
                    span.template-card-header{
                        display: block;
                        font-weight: 500;
                        color: #B0C3DB;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .template-icon{
                        width: 40px;
                    }
                }
                .template-card-active{
                    background-color: #303740;
                    border: 1px solid #868686;
                }
            }
        }
        .cutomise-app-builder{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            .customise-settings{
                display: flex;
                height: 56vh;
                flex-grow: 1;
                @media screen and (max-width: 924px) {
                    @include custom-scrollbar;
                    flex-direction: column-reverse;
                    overflow-y: auto;
                    overflow-x: hidden;
                    height: 48vh;
                    margin-top: 20px;
                }
                @media screen  and (max-height:590px){
                    height: 50vh;
                }
                .customise-settings-bar{
                    @include custom-scrollbar;
                    overflow-y: auto;
                    overflow-x: hidden;
                    height: 95%;
                    width: 35%;
                    display: flex;
                    flex-direction: column;
                    
                    @media screen and (max-width: 924px) {
                        width: 100%;
                        height: auto;
                        overflow-y: unset;
                        overflow-x: unset;
                    }
                    .customise-setting-main-row{
                        #customise-color-picker{
                            width: 12px;
                            height: 12px;
                            padding: 0;
                            margin: 0;
                            margin-left: 10px;
                            border: 0px;
                            border-radius: 50%;
                            overflow: hidden;
                            cursor: pointer;
                            &::-webkit-color-swatch-wrapper {
                                padding: 0;
                            }
                            &::-webkit-color-swatch {
                                border: none;
                            }
                        }
                        #brand-color-tooltip{
                            cursor: pointer;
                        }
                        .customise-file-name{
                            width: 60px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        &:hover{
                            .customise-setting-name{
                                color: white;
                            }
                        }
                        .customise-setting-name{
                            color: #B0C3DB;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 20px;
                        }
                        .customise-dropdown-btn{
                            position: relative;
                            padding: 0.35rem 0.5rem;
                            background-color: #303740;
                            color: white !important;
                            text-transform: capitalize;
                            min-width: 96px;
                            display: flex;
                            justify-content: space-between;
                        }
                        .verticle-devider{
                            background-color: #1D232B;
                        }
                    }
                    .customise-plugin-icon{
                        width: 24px;
                        img{
                            width: 100%;
                        }
                    }
                    .customise-plugin-header{
                        color: #B0C3DB;
                        font-size: 16px;
                        width: calc(100% - 34px);
                        display: flex;
                        justify-content: space-between;
                        align-content: center;
                        margin-left: 10px;
                        
                        @include custom-toggle-button;
                    }
                    .customise-plugin-desc{
                        width: calc(100% - 34px);
                        margin-top: 2px;
                        padding-right: 6px;
                        color: #B0C3DB;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 16px;
                    }
                }
                .customise-display-screen{
                    width: 65%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    @media screen and (max-width: 924px) {
                        width: 100%;
                        justify-content: center;
                    }
                    // video-preview screen styling
                    .customise-screen{
                        position: relative;
                        width: 95%;
                        height: 90%;
                        min-height: 350px;
                        display: flex;
                        flex-direction: column;
                        border-radius: 5px;
                        transition: 0.3s;
                        &::after{
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            visibility: hidden;
                            opacity: 0;
                            transition: 0.5s;
                            background-color: black;
                        }
                        &.screen-zoom-true{
                            &::after{
                                opacity: 1;
                                transition: 0s;
                                visibility: visible;
                            }
                            .customise-screen-top-bar{
                                .customise-top-bar-logo{
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    height: 40%;
                                    img{
                                        height: 100%;
                                    }
                                    transform: translate(-50%, -50%);
                                    z-index: 1;
                                }
                            }
                        }
                        overflow: hidden;
                        @media screen and (max-width: 924px) {
                            min-height: 300px;
                        }
                        @media screen and (max-height: 624px) {
                            height: 90%;
                            min-height: auto;
                        }
                        .customise-screen-top-bar{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0px 10px;
                            padding-top: 5px;
                            font-size: 10px;
                            color: #C7C7C7;
                            .customise-top-bar-logo{
                                height: auto;
                                transition: 0.5s;
                                img{
                                    height: 20px;
                                }
                            }
                            .customise-top-people-info{
                                cursor: pointer;
                                span{
                                    &:hover{
                                        color: white;
                                    }
                                }
                                font-size: 9px;
                                svg{
                                    margin-right: 4px;
                                }
                                &>div:first-child{
                                    margin-right: 10px;
                                }
                            }
                            .customise-top-bar-people{
                                &:hover{
                                    cursor: pointer;
                                    color: white;
                                }
                                span{
                                    margin-right: 4px;
                                }
                            }
                        }
                        .customise-video-screen{
                            width: 100%;
                            user-select: none;
                            overflow: hidden;
                            display: flex;
                            flex-grow: 1;
                            // brand-color-preview styling
                            .brand-color-screen{
                                width: 75%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                $dialogs-width: 70%;
                                &.dark-brand-dialog{
                                    .brand-color-dialog{background-color: white;
                                        box-shadow: 0px 10px 25px 0px #00000040;
                                        h5{
                                            color: black !important;
                                        }
                                        
                                        .verticle-devider{          
                                            border-top: 1px solid #E3E3E3;
                                        }
                                        .btn-light{
                                            background: #E3E3E3;
                                            color: black;
                                            border: 0;
                                            font-weight: 500;
                                        }
                                    }
                                    .brand-color-input-wrapper{
                                        .form-control{
                                            background-color: #E3E3E3;
                                            color: black;
                                            font-weight: 500;
                                            
                                        }
                                    }
                                }
                                .brand-color-dialog{
                                    padding: 15px 10px;
                                    width: $dialogs-width;
                                    .brand-color-dialog-content{
                                        padding: 20px 0;
                                    }
                                    .brand-color-dialog-button-wrapper{
                                        display: flex;
                                        justify-content: flex-end;
                                    }
                                }
                                .brand-color-input-wrapper{
                                    width: $dialogs-width;
                                    display: flex;
                                    justify-content: space-between;
                                    .form-control{
                                        border: 2px solid transparent;
                                        margin-right: 20px;
                                        &:focus{
                                            border: 2px solid var(--user-theme-color);
                                        }
                                    }
                                }
                                .btn{
                                    border-radius: 8px;
                                }
                                .btn-light{
                                    background: #3B3B3B;
                                    margin-right: 5px;
                                }
                                .btn-user-theme-primary{
                                    background: var(--user-theme-color);
                                    &:focus{
                                        box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.10);
                                    }
                                }
                            }
                            .customise-audienece-screen{
                                width: 75%;
                                padding: 20px 30px;
                                margin-left: 20px;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                align-content: center;
                                // display: grid;
                                // grid-template-columns: 1fr 1fr;
                                // justify-content: center; 
                                // justify-items: stretch;
                                // grid-gap: 10px;
                                // .audience-place-end-card{
                                //     justify-self: end;
                                // }
                                .customise-audienece-card{
                                    &.square-audience-card{
                                        width: 100px;
                                        height: 100px;
                                        @media screen and (max-width: 600px){
                                            width: 80px;
                                            height: 80px;
                                        }
                                        @media screen and (max-width: 425px){
                                            width: 40%;
                                            height: 30%;
                                        }
                                        @media screen and (max-height: 668px) {
                                            width: 100px;
                                            height: 100px;
                                        }
                                    }
                                    &.wide-audience-card{
                                        margin: 5px;
                                        @media screen and (min-width:1068px) {
                                            width: 45%;
                                            height: 100px;
                                        }   
                                        @media screen and (max-width:1068px) {
                                            width: 100px;
                                            height: 70px;
                                        }
                                        @media screen and (max-width:668px) {
                                            width: 80px;
                                            height: 60px;
                                        }
                                    }
                                    margin: 5px;
                                    // aspect-ratio: 1;
                                    transition: 0.5s;
                                    border-radius: 5px;
                                    overflow: hidden;
                                    position: relative;
                                    display: flex;
                                    justify-content: center;
                                    img{
                                        width: 70%;
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                            .customise-side-bar{
                                width: 25%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .customise-speaker-card{
                                    position: relative;
                                    transition: 0.5s;
                                    border-radius: 5px;
                                    overflow: hidden;
                                    display: flex;
                                    justify-content: center;
                                    img{
                                        margin-bottom: 20px;
                                    }
                                }
                            }
                            .audience-initials-header{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: white;
                                background-color: var(--user-theme-color);
                                transition: 0.5s;
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                font-weight: 500;
                                font-size: 28px;
                                display: flex;
                                justify-content: center;
                                align-items: center;   
                                @media screen and (max-width:1068px) {
                                    height: 50px;
                                    width: 50px;
                                    font-size: 24px;
                                }     
                            }
                            .customise-audienece-card-header{
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 20px;
                                letter-spacing: 0em;
                                text-align: center;
                                background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
                            }
                            .speaking-card{
                                box-shadow: 0px 0px 2px var(--user-theme-color), 0px 0px 30px var(--user-theme-color);
                            }
                        }
                        .customise-control-bar{
                            position: relative;
                            height: auto;
                            padding: 5px;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .customise-plugins-bar{
                                display: flex;
                                align-items: center;
                                .customise-settings-bar-wrapper{
                                    margin-left: 10px;
                                    margin-right: 5px;
                                    img{
                                        cursor: pointer;
                                        &:hover{
                                            filter: brightness(70%);
                                        }
                                        @media screen and (min-height: 868px) {
                                            width: 25px !important;
                                        }
                                    }
                                }
                                .main-plugins-indicator-bar{
                                    .hide-on-mobile{
                                        @media screen and (max-width: 632px){
                                            display: none;   
                                        }
                                    }
                                    &.light-indicator-icons{
                                        position: relative;
                                        &:before{
                                            position: absolute;
                                            height: calc(100% + 10px);
                                            width: calc(100% + 10px);
                                            background-color: grey;
                                            border-radius: 50%;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, 50%);
                                        }
                                    }
                                    display: flex;
                                    align-items: center;
                                    padding-left: 5px;
                                    img{
                                        cursor: pointer;
                                        &:hover{
                                            filter: brightness(70%);
                                        }
                                        @media screen and (min-height: 868px) {
                                            width: 25px !important;
                                        }
                                    }
                                }
                                .customise-chat-icon-wrapper{
                                    margin-left: 5px;
                                    position: relative;
                                    .chat-dot{
                                        height: 6px;
                                        width: 6px;
                                        background: var(--user-theme-color);
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 4px;
                                        right: 0;
                                        @media screen and (min-height: 868px) {
                                            height: 12px;
                                            width: 12px;
                                            top: 0px;
                                        }
                                    }
                                    img{
                                        cursor: pointer;
                                        &:hover{
                                            filter: brightness(70%);
                                        }
                                        @media screen and (min-height: 868px) {
                                            width: 25px !important;
                                        }
                                    }
                                }
                                .customise-hand-icon-wrapper{
                                    margin-left: 10px;
                                    position: relative;
                                    background: white;
                                    border-radius: 4px;
                                    padding: 2px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    &:hover{
                                        filter: brightness(70%);
                                    }
                                    img{
                                        height: 16px;
                                        width: 16px;
                                        cursor: pointer;
                                        @media screen and (min-height: 868px) {
                                            width: 25px !important;
                                            height: 25px !important;
                                        }
                                    }
                                }
                            }
                            .stream-control-buttons{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                img{
                                    cursor: pointer;
                                    margin: 0 5px;
                                    transition: 0.3s;
                                    &:hover{
                                        filter: brightness(70%);
                                    }
                                    @media screen and (min-height: 868px) {
                                        width: 25px !important;
                                    }
                                }
                            }
                            .customise-button-wrapper{
                                display: flex;
                                height: 100%;
                                .btn{
                                    @media screen and (min-height: 868px) {
                                        font-size: 18px;
                                    }
                                    &.btn-light, &.btn-secondary{
                                        margin-right: 10px;
                                    }
                                    &.btn-secondary{
                                        background: #E3E3E3;
                                    }
                                    border-radius: 5px;
                                    font-size: 10px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    img{
                                        margin-right: 3px;
                                        @media screen and (min-height: 868px) {
                                            width: 25px !important;
                                        }
                                    }
                                }
                            }
                        }

                        // Theme Options
                        &.screen-dark{
                            background-color: black;
                        }
                        &.screen-light{
                            background-color: white;
                            .customise-screen-top-bar{
                                color: black;
                            }
                        }
                    }
                }
            }
        }
        .deploy-app-builder{
            width: 100%;
            .deploy-details-hide{
                position: relative;
                filter: brightness(40%);
                &::before{
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    z-index: 5000;
                }
            }
            .deploy-options{
                display: grid;
                grid-template-columns: 255px 1fr;
                @media screen and (max-width: 720px) {
                    grid-template-columns: 1fr;
                }
                color: #B0C3DB;
                .recommended-button{
                    margin-left: 10%;
                    text-transform: uppercase;
                    line-height: 16px;
                    font-size: 10px;
                    font-weight: 500;
                    color: white;
                    background: #12B536;
                    border-radius: 4px;
                    padding: 2px 8px;
                }
                .deploy-domains{
                    @media screen and (max-width: 720px) {
                        display: flex;
                        .deploy-action-header{
                            display: none;
                        }
                    }
                }
                .deploy-domain-button{
                    display: flex;
                    margin-top: 5px;
                    background-color: transparent;
                    border: 0;
                    color: #B0C3DB;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    padding: 14px 20px;
                    border-radius: 8px;
                    cursor: pointer;
                    svg{
                        fill: #B0C3DB;
                        margin-right: 10px;
                    }
                    &:hover{
                        background: #1D232B;
                        color: white;
                        svg{
                            fill: white;
                        }
                    }
                    &.active-domain-button{
                            background: #303740;
                            color: white;
                            svg{
                                fill: white;
                            }
                    }
                    @media screen and (max-width: 720px) {
                        justify-content: center;
                    }
                }
                .deploy-action{
                    padding: 10px 15px;
                    padding-top: 15px;
                    margin: 0 20px;
                    @media screen and (max-width: 720px) {
                        margin: 0;
                        margin-top: 5px;
                    }
                    margin-top: 5px;
                    border-radius: 8px;
                    background: #1D232B; 
                    color: white;
                    .deploy-heroku{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .copy-link-input{
                            flex-grow: 1;
                            width: 200px;
                            display: flex;
                            font-size: 14px;
                            justify-content: space-between;
                            align-items: center;
                            input{
                                width: 100%;
                                margin-top: 5px;
                                padding: 8px;
                                background: #303740;
                                border: 0;
                                border-radius: 8px;
                                color: rgb(235, 235, 235);
                                margin-right: 20px;
                            }
                        }
                        .copy-link-text{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #2F80FF;
                            font-weight: 500;
                            width: max-content;
                            cursor: pointer;
                            height: 32px;
                            outline: none;
                        }
                    }
                }
            }
        }
    }
    #customise-logo-upload{
        position: absolute;
        visibility: hidden;
    }
}

.landing-page-bg{
    position: absolute;
    top: 0;
    width: calc(100% + 10px);
    height: 100%;
    z-index: -1;
    overflow: hidden;
    img{
        position: absolute;
        bottom: -10%;
        left: 0%;
        height: 100%;
        min-width: 100%;
        max-width: 100%;
    }
}

.go-back-btn{
    position: sticky;
    bottom: 0;
}

.verticle-devider{
    width: 100%;
    border-top: 1px solid #303740;
    margin: 5px 0 10px 0;
}

.horizontal-devider{
    height: 18px;
    border-left: 0.380208px solid rgba(119, 119, 119, 0.5);
}

.coming-soon{
    position: relative;
    &:hover{
        background-color: #1B2028 !important;
        cursor: default !important;
    }
    &:before{
        content: '';
        position: absolute;
        background-color: #1B2028 !important;
        height: 100%;
        width: 100%;
        z-index: 0;
        opacity: 0.4 !important;
    }
    &::after{
        position: absolute;
        top: 8px;
        right: 8px;
        content: 'COMING SOON';
        line-height: 16px;
        font-size: 10px;
        font-weight: 500;
        color: white;
        background-color: #2F80FF;
        border-radius: 4px;
        padding: 2px 8px;
    }
}

.hide-on-mobile{
    @media screen and (max-width: 632px){
        display: none !important;
    }
}

// font options
.customise-font-lato{
    font-family: var(--user-theme-font-lato);
}
.customise-font-montserrat{
    font-family: var(--user-theme-font-montserrat);
}

.customise-font-openSans{
    font-family: var(--user-theme-font-openSans);
}

.customise-font-ibm{
    font-family: var(--user-theme-font-ibm);
}
