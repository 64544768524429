.mytooltip {
  .tooltip-inner {
    max-width: 100% !important;
    white-space: normal !important;
  }

  .popper {
    max-width: none !important;
  }
}

.mytooltip-xl {
  .tooltip-inner {
    max-width: 400px !important;
    white-space: normal !important;
  }

  .popper {
    max-width: 400px !important;
  }
}
