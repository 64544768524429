
$error: #CB525E;
$sucess: #36B580;
$warning: #FFAB00;
$surface-default: #13161B;
$border-light: #2D3440;
$text-med-emp: #E0ECFFCC;

#toast-container {
  > div {
    box-shadow: $box-shadow;
    opacity: 1;
    color: $text-med-emp !important;
    &:hover {
      box-shadow: $box-shadow;
      opacity: 0.9;
    }
  }

  &.toast-top-full-width,
  &.toast-bottom-full-width {
    > div {
      min-width: 96%;
      margin: 4px auto;
    }
  }
}

// for error

.toastr-options {
  padding: 24px;
  background-color: lighten($gray-200, 2%);
  margin-bottom: 0;
  border: 1px solid $border-color;
}

.toast-success,.toast-warning,.toast-error {
  background-color: $surface-default !important;
  border: 2px solid $border-light !important;
  border-radius: 8px !important;
  padding: 24px;
  font-size: 14px;
}

.toast-error:before,.toast-success:before,.toast-warning:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
}

.toast-error:before {
  background-color: $error !important;
}

.toast-success:before {
  background-color: $success !important;
}

.toast-warning:before {
  background-color: $warning !important;
}

.toast-close:hover {
  color: $text-med-emp !important;
}