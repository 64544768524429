.introjs-tooltip {
  background-color: rgba(48, 55, 64, 1);
  border-radius: 8px;
  padding: 14px 16px !important;
}

.introjs-button {
  font-size: 14px;
}

.introjs-button.introjs-skipbutton:not(.introjs-donebutton) {
  color: #ffffff66 !important;
  &:hover, &:focus {
      border-color: #ffffff66 !important;
  }
}

.introjs-bullets {
  display: none;
}

.introjs-tooltiptext {
    margin-left: 6px;
    margin-top: 6px;
    margin-bottom: 12px;
}

.introjs-button.introjs-prevbutton {
    border-color: transparent !important;
    color: #ffffff66 !important;
    &:hover, &:focus {
        border-color: #ffffff66 !important;
    }
}