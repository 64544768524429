// Dark Theme
@import "app-dark.scss";

body {
  font-family: "FFClanPro", "Segoe UI", sans-serif;
}

.account-pages {
  height: 100vh;
}

.main-content {
  padding: 0;
  min-height: calc(100vh - 60px);
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.table td,
.table th {
  padding: 1rem 0.5rem;
}

table,
.table {
  font-size: 0.75rem;
}

tr:hover {
  th:first-child,
  td:first-child {
    border-top-left-radius: $border-radius-sm;
    border-bottom-left-radius: $border-radius-sm;
  }

  th:last-child,
  td:last-child {
    border-top-right-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
  }
}

.card-body:hover {
  .card-link {
    color: $primary;
    transition: all 0.2s ease;
  }
}

.separator {
  border-color: hsla(218, 17%, 13%, 1) !important;
}

.tbody-container {
  td {
    padding: 28px 0;
    padding-left: 20px;
    min-width: 100px;
  }

  &.compact td {
    padding: 16px 0;
    padding-left: 10px;
    min-width: 100px;
  }

  tr {
    border-bottom: 1px solid;
    border-color: inherit;
  }

  &.hide-bottom-borders tr {
    border-bottom: none;
    border-color: none;
  }

  &.center-final-col tr td:last-of-type {
    padding-left: 0;
  }

  td.align-middle {
    padding: 1rem 12px;
  }
  &.no-hover-effect {
    tr {
      background-color: #13161b;
    }
    tr:nth-child(odd) {
      background-color: hsla(210, 15%, 5%, 1);
    }
  }

  td {
    vertical-align: middle;
  }

  &.highlight-all td {
    background-color: #13161b;
  }

  &.highlight-all tr {
    border-bottom: 1px solid #1c2027;
  }
}

.table-new-header th {
  padding-left: 12px;
}

.t-head {
  padding: 16px 8px;
}

th.t-head:last-of-type {
  padding-left: 50px;
}

.container-fluid {
  padding: 0;
}
ul.parallel-line {
  margin-left: 1.25rem;
}

ul.parallel-line div li {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 1.5rem;
  @media screen and (max-width: 1440px) {
    padding-bottom: 1rem;
  }

  /* For the parallel line */
  &::before {
    content: "";
    position: absolute;
    left: -16px;
    height: 100% !important;
    width: 1px;
  }

  /* For the bullet */
  &::after {
    content: "\2022";
    color: inherit;
    position: absolute;
    font-weight: 900;
    left: -21.5px;
    top: -8px;
  }
}

ul.parallel-line {
  div:last-child {
    li::before {
      height: 6px !important;
    }
  }
  div:first-of-type {
    li::before {
      top: 6px !important;
    }
  }
}

.truncated {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 190px;
  text-overflow: ellipsis;
}

.truncated-long {
  width: 210px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.row-container:last-child {
  & .right-cell {
    border-bottom-right-radius: 8px;
  }
  & .left-cell {
    border-bottom-left-radius: 8px;
  }
}
