//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  color: $gray-200 !important;
}

.btn-outline-light {
  color: $gray-900;
}

.google-button {
  background-color: white;
  &:hover, &:focus {
    box-shadow: 0 0 3px 3px #4285f4;
  }
}

.google-button-disabled {
  background-color: white;
}

.github-button {
  background: #ffffff1a;
  border: 1px solid #ffffff1f;
  &:hover, &:focus {
    box-shadow: 0 0 3px 3px #4285f4;
  }
}

.github-button-disabled {
  background: #ffffff1a;
  border: 1px solid #ffffff1f;
}

.is-social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 32px;
  background: #303740;
  border-radius: 8px;
  margin: auto;
  &:hover, &:focus {
    box-shadow: 0 0 3px 3px #4285f4;
  }
}

