

.child,
.child2,
.child3 {
  display: flex;
}

.deployedAppFooter {
  width: 100%;
  // opacity: 0;
  // bottom: -4px;
  position: relative;
}

.dialogOpen {
  
}

.parent:hover .deployedAppFooter, .parent.dialogOpen .deployedAppFooter{
  display: flex;
  bottom: 0;
  transition: all ease-in;
  transition-duration: 0.2s;
  opacity: 1;
}

.child {
  display: none;
}

.child2 {
  display: none;
}

.child3 {
  display: flex;
}

.radius {
  border-radius: 0.5rem !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollhide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollhide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.subtitle {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}

.title {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}



.icon-color { 
  svg { 
    color:inherit;
  }
}